<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
      :tableItems="adminUsers"
      :newRecord="newRecord"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    SMART_FORM_FIELD_TYPE
  } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    ADMIN_USER_GET_ADMIN_USERS,
    ADMIN_USER_INITIAL_GET_ADMIN_USERS_STATE
  } from '@/core/store/admin-user.module';

  export default {
    name: 'AccountManagementList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.accountManagement'),
            route: { name: ROUTE_NAME.ACCOUNT_MANAGEMENT }
          },
          { title: i18nHelper.getMessage('label.accountManagementList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.ADMIN_USER,
        actions: {
          create: ACTION.ADMIN_USER_CREATE,
          read: ACTION.ADMIN_USER_READ,
          update: ACTION.ADMIN_USER_UPDATE,
          delete: ACTION.ADMIN_USER_DELETE
        }
      },
      filters: [
        {
          label: 'name',
          key: 'name',
          value: ''
        },
        {
          label: 'username',
          key: 'username',
          value: ''
        },
        {
          label: 'email',
          key: 'email',
          value: ''
        },
        {
          label: 'accountStatus',
          key: 'isActive',
          value: null,
          options: listService.getStatusList(),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        },
        {
          label: 'createdDateRange',
          key: 'createdDateRange',
          value: null,
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        },
        {
          label: 'updatedDateRange',
          key: 'updatedDateRange',
          value: null,
          type: SMART_FORM_FIELD_TYPE.DATE_RANGE
        }
      ],
      actions: {
        viewList: {
          module: 'adminUser',
          state: 'adminUsers',
          action: ADMIN_USER_GET_ADMIN_USERS,
          initialStateAction: ADMIN_USER_INITIAL_GET_ADMIN_USERS_STATE,
          title: i18nHelper.getMessage('label.getAdminUsers')
        },
        viewDetails: {
          routeName: ROUTE_NAME.ACCOUNT_MANAGEMENT_DETAILS
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          sortable: true
        },
        {
          key: 'username',
          name: 'username',
          active: true,
          sortable: true
        },
        {
          key: 'role',
          name: 'role',
          active: true,
          sortable: true
        },
        {
          key: 'email',
          name: 'email',
          active: true,
          sortable: true
        },
        {
          key: 'isActive',
          name: 'accountStatus',
          active: true,
          sortable: true,
          layout: 'AppActivationStatusLabel'
        },
        {
          key: 'createdAt',
          name: 'createdAt',
          active: true,
          sortable: true,
          sortField: 'createdAtTimeStamp'
        },
        {
          key: 'updatedAt',
          name: 'updatedAt',
          active: true,
          sortable: true,
          sortField: 'updatedAtTimeStamp'
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: 'AppTableActions'
        }
      ],
      adminUsers: [],
      newRecord: {
        show: true,
        buttonTitle: i18nHelper.getMessage('label.newUser'),
        routeName: ROUTE_NAME.ACCOUNT_MANAGEMENT_NEW
      }
    })
  };
</script>

<style></style>
